import { Dispatch, useState, SetStateAction, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Auth } from "../../../api/auth";
import { Button } from "../../../components/Button";
import { useCookies } from "react-cookie";

export const VerifyLogin = ({
  codeSent,
  setCodeSent,
}: {
  codeSent: string;
  setCodeSent: Dispatch<SetStateAction<string>>;
}) => {
  const [email] = useState(localStorage.getItem("loginServiceId"));
  const [code, setCode] = useState<string>();
  const [, setCookie] = useCookies();
  const handleOtp: (number: string) => void = (otp: string) => setCode(otp);

  useEffect(() => {
    (async () => {
      try {
        if (!code) {
          return;
        }

        if (!email) {
          return;
        }

        if (code.length === 6) {
          const { data } = await Auth.verifyEmail({ id: codeSent, code });
          if (!data) {
            return;
          }
          const { token } = data;
          if (token) {
            setCookie("access_token", token, {
              path: "/",
              // check if env is production
              domain:
                process.env.NODE_ENV === "production" ? ".dono.gg" : undefined,
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [code]);

  return (
    <div className="max-w-[380px]">
      <h1 className="text-3xl font-black mb-4">کد ورود ارسال شد</h1>
      <p className="text-black/80 leading-8 mb-8">
        یک کد ورود برای <strong>{email}</strong> ارسال شده است. در صورتی که این
        کد در اینباکس ایمیل شما یافت نشد لطفا پوشه اسپم خودتون رو چک کنید.
      </p>
      <div className="otp-form mb-8">
        <OtpInput
          value={code}
          onChange={handleOtp}
          numInputs={6}
          separator={<div className="px-1" />}
        />
      </div>
      <div className="flex gap-x-4">
        <Button type="primary">ورود به سایت</Button>
        <Button type="secondary" onClick={() => setCodeSent("")}>
          ارسال مجدد
        </Button>
      </div>
    </div>
  );
};
